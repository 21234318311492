import { entityApi } from "@entities/Portal/Entity";

import { openDrawer } from "@shared/ui/DrawerManager";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { Spin } from "antd";
import { useMemo, useState } from "react";
import { ERP_LOCATION_PATTERN } from "../constants";

interface ISelectEntityRowReadValueItem {
    value: any;
    options: TPaginatedSelectOption[];
    entityInfo: string;
    byUuid?: boolean;
}

const SelectEntityRowReadValueItem = ({
    value,
    options,
    entityInfo,
    byUuid,
}: ISelectEntityRowReadValueItem) => {
    const [tableName, setTableName] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const label = useMemo(() => {
        return options.find((item) => item.value === value)?.label;
    }, [value, options]);

    const onClick = (e: any) => {
        e.stopPropagation();
        e.preventDefault();

        if (tableName) {
            openDrawer({
                id: value,
                name: tableName,
                pattern: ERP_LOCATION_PATTERN,
            });
            return;
        }

        setLoading(true);
        (byUuid
            ? entityApi.getEntityByUuid(entityInfo)
            : entityApi.getEntityByTableName(entityInfo)
        )
            .then((response) => {
                const tableName = response.data.data.table_name;
                setTableName(tableName);
                openDrawer({
                    id: value,
                    name: tableName,
                    pattern: ERP_LOCATION_PATTERN,
                });
            })
            .catch(() => {
                notificationEmit({
                    title: "Не удалось открыть!",
                    type: "error",
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };
    return (
        <div className="link" style={{ padding: 3 }} onClick={onClick}>
            {label}
            {loading && <Spin />}
        </div>
    );
};

export default SelectEntityRowReadValueItem;
