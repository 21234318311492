import { apiUrl } from "@shared/api/api";
import { PaginatedSelect } from "@shared/ui/PaginatedSelect";
import { useEffect, useRef } from "react";

interface ISelectStageInput {
    value: string;
    onChangeValue: (value: string | null | string[]) => void;
    onlyRead?: boolean;
    disabled?: boolean;
    additionalParameters: TSelectAdditionalParameters;
    field: TEntityField;
    fieldsArray?: (TEntityField & { value: any })[];
}

const SelectStageInput = ({
    value,
    onChangeValue,
    additionalParameters,
    onlyRead,
    fieldsArray,
    field,
}: ISelectStageInput) => {
    const firstRender = useRef(true);
    const categoryValue = fieldsArray
        ? fieldsArray?.find((field) => field.datatype === "category")?.value
        : null;
    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
        } else {
            if (!onlyRead) {
                onChangeValue(isIncludeFilter ? [] : null);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryValue]);
    const isIncludeFilter = additionalParameters?.isIncludeFilter;
    return (
        <PaginatedSelect
            coloredKey={"background_color"}
            valueToString
            onlyRead={onlyRead}
            multiple={field.is_multiple || isIncludeFilter}
            requestUrl={
                (!Array.isArray(categoryValue) && categoryValue) ||
                (Array.isArray(categoryValue) && categoryValue.length === 1)
                    ? `${apiUrl}/api/entity-uuid/${field.entity_uuid}/categories/${categoryValue}/stages`
                    : `${apiUrl}/api/entity-uuid/${field.entity_uuid}/all-categories/all-stages`
            }
            value={isIncludeFilter ? (value ? value : []) : value}
            valueKey="id"
            labelKey="label"
            onChangeValue={onChangeValue}
            initialOptions={additionalParameters?.options ?? []}
        />
    );
};

export default SelectStageInput;
