import { apiUrl } from "@shared/api/api";
import { PaginatedSelect } from "@shared/ui/PaginatedSelect";

interface ICategorySelect {
    multiple?: boolean;
    onlyRead?: boolean;
    value: number | string | null | number[] | string[];
    entityId: number;
    initialOptions?: TOption[];
    onChangeValue: (value: any) => void;
    smallVersion?: boolean;
}

const CategorySelect = ({
    multiple,
    onlyRead,
    value,
    initialOptions,
    entityId,
    onChangeValue,
    smallVersion,
}: ICategorySelect) => {
    return (
        <PaginatedSelect
            onlyRead={onlyRead}
            multiple={multiple}
            requestUrl={`${apiUrl}/api/entity/${entityId}/categories`}
            value={value}
            placeholder="Категория"
            valueKey="id"
            labelKey="label"
            onChangeValue={onChangeValue}
            initialOptions={initialOptions ?? []}
            smallVersion={smallVersion}
        />
    );
};

export default CategorySelect;
