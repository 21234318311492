import { ArrowLeftOutlined, ArrowRightOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Checkbox, Flex, Popconfirm, Select, Tooltip } from "antd";
import { useSpringCarousel } from "react-spring-carousel";
import EntityRowsActionsChangeStage from "./EntityRowsActionsChangeStage";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import "./EntityRowActionsCarousel.scss";

interface IEntityRowActionsCarousel {
    entityId: number;
    selectedRows: number[];
    onSelectRows: (rows: any[]) => void;
    allowDelete?: TCapabilityLevel;
    onDelete: () => void;
    deleteLoading: boolean;
    onChangeAdditionalCalculation: (event: CheckboxChangeEvent) => void;
    isCalculateAdditionalEstimation: boolean;
    choosenEntityFieldsKeysForEstimation: string[];
    entityFieldsOptions: {
        label: string;
        value: string;
        entityField: TEntityField;
    }[];
    onChooseEntityFieldsKeysForEstimation: (value: string[]) => void;
    allowUpdate?: TCapabilityLevel;
}
const EntityRowActionsCarousel = ({
    selectedRows,
    allowDelete,
    onDelete,
    deleteLoading,
    onChangeAdditionalCalculation,
    isCalculateAdditionalEstimation,
    choosenEntityFieldsKeysForEstimation,
    entityFieldsOptions,
    onChooseEntityFieldsKeysForEstimation,
    allowUpdate,
    entityId,
    onSelectRows,
}: IEntityRowActionsCarousel) => {
    const { carouselFragment, slideToPrevItem, slideToNextItem } = useSpringCarousel({
        itemsPerSlide: 1,
        shouldResizeOnWindowResize: true,
        withLoop: true,
        items: [
            {
                id: "1",
                renderItem: (
                    <Flex
                        wrap="wrap"
                        align="center"
                        justify="center"
                        style={{ width: "100%" }}
                        gap={2}
                    >
                        <div className="rows-actions__count">{`Выделено: ${selectedRows.length}`}</div>
                        {allowDelete && (
                            <div>
                                <Popconfirm
                                    title="Удалить выбранные записи?"
                                    okText="Удалить"
                                    okButtonProps={{
                                        danger: true,
                                    }}
                                    onConfirm={onDelete}
                                    cancelText="Отмена"
                                >
                                    <Button
                                        disabled={selectedRows.length === 0}
                                        danger
                                        loading={deleteLoading}
                                        type="link"
                                        icon={<DeleteOutlined />}
                                    >
                                        Удалить
                                    </Button>
                                </Popconfirm>
                            </div>
                        )}
                    </Flex>
                ),
            },
            {
                id: "2",
                renderItem: (
                    <Flex
                        gap={2}
                        wrap="wrap"
                        justify="center"
                        vertical
                        style={{ width: "100%" }}
                    >
                        <Flex>
                            <Tooltip title="Суммировать выбранные строки по полям">
                                <Checkbox
                                    style={{ width: "fit-content" }}
                                    onChange={onChangeAdditionalCalculation}
                                    checked={isCalculateAdditionalEstimation}
                                >
                                    <span style={{ textWrap: "nowrap" }}>
                                        Суммировать
                                    </span>
                                </Checkbox>
                            </Tooltip>
                        </Flex>
                        {isCalculateAdditionalEstimation && (
                            <Select
                                size="small"
                                placeholder="Поля для расчета"
                                mode="multiple"
                                showSearch={false}
                                allowClear
                                value={choosenEntityFieldsKeysForEstimation}
                                options={entityFieldsOptions}
                                style={{ width: "95%", minHeight: 0 }}
                                maxTagCount={1}
                                maxTagTextLength={6}
                                onChange={onChooseEntityFieldsKeysForEstimation}
                            />
                        )}
                    </Flex>
                ),
            },
            ...(allowUpdate
                ? [
                      {
                          id: "3",
                          renderItem: (
                              <Flex
                                  gap={2}
                                  wrap="wrap"
                                  align="center"
                                  justify="center"
                                  style={{ width: "100%" }}
                              >
                                  <EntityRowsActionsChangeStage
                                      entityId={entityId}
                                      selectedRows={selectedRows}
                                      onSuccess={() => {
                                          onSelectRows([]);
                                      }}
                                      smallVersion={true}
                                  />
                              </Flex>
                          ),
                      },
                  ]
                : []),
        ],
    });

    return (
        <Flex
            wrap="nowrap"
            align="center"
            justify="space-between"
            className="carousel-container"
            style={{ width: "100%", maxWidth: "calc(100vw - 72px)" }}
        >
            <Button type="text" icon={<ArrowLeftOutlined />} onClick={slideToPrevItem} />
            <Flex
                align="center"
                justify="center"
                style={{ width: "calc(100% - 72px)", overflow: "hidden" }}
            >
                {carouselFragment}
            </Flex>
            <Button type="text" icon={<ArrowRightOutlined />} onClick={slideToNextItem} />
        </Flex>
    );
};

export default EntityRowActionsCarousel;
