import { $api } from "@shared/api/api";
import { dbGetStoreDataItem, dbInitStore, dbPutDataArray } from "@shared/lib/indexDb";
import { useEffect, useRef, useState } from "react";
interface IUsePaginatedSelect {
    requestUrl: string;
    initialOptions?: TPaginatedSelectOption[];
    valueKey: string;
    coloredKey?: string;
    value: any;
    valueToString?: boolean;
    onlyRead?: boolean;
    extraParams?: any;
    labelKey?: string;
    storeName?: string;
}

export const usePaginatedSelect = ({
    requestUrl,
    initialOptions,
    value,
    valueToString,
    onlyRead,
    valueKey,
    labelKey,
    coloredKey,
    storeName,
    extraParams,
}: IUsePaginatedSelect) => {
    const [loading, setLoading] = useState(true);
    const isLoaded = useRef(false);
    const [searchValue, setSearchValue] = useState("");
    const timer = useRef<any>();
    const [options, setOptions] = useState<TPaginatedSelectOption[]>(
        initialOptions ?? []
    );
    const initStore = (storeName: string, valueKey: string) => {
        return dbInitStore({
            name: storeName,
            tables: [
                {
                    name: "data",
                    keyPath: valueKey,
                },
            ],
        });
    };
    const getValuesFromStore = async (values: (number | string)[]) => {
        if (storeName && (valueKey === "id" || valueKey === "uuid")) {
            const success = await initStore(storeName, valueKey);
            if (!success) return false;
            const items: any = [];
            const result = await new Promise((resolve, reject) => {
                values.forEach(async (value, index, arr) => {
                    const readedItem = await dbGetStoreDataItem(
                        storeName,
                        "data",
                        valueKey === "id" ? Number(value) : value
                    );
                    if (!readedItem) return resolve(false);
                    items.push(readedItem);
                    if (arr.length === index + 1) resolve(true);
                });
            });
            if (result) {
                setOptions([...options, ...items]);
            }

            return result;
        }
    };

    const loadOptions = (params?: any) => {
        setLoading(true);

        $api.get("", {
            baseURL: requestUrl,
            params: { ...params, ...(extraParams ?? {}) },
        })
            .then((response) => {
                setOptions(response.data.data);

                if (storeName && (valueKey === "id" || valueKey === "uuid")) {
                    initStore(storeName, valueKey).then(() => {
                        dbPutDataArray(storeName, "data", response.data.data);
                    });
                }
            })
            .catch(() => {})
            .finally(() => {
                isLoaded.current = true;
                setLoading(false);
            });
    };
    const loadOnFocus = () => {
        if (!isLoaded.current) {
            loadOptions();
        }
    };

    useEffect(() => {
        let valuesIsInOptions = true;
        const valuesArr = Array.isArray(value) ? value : [value];
        if (value) {
            if (onlyRead || value != null) {
                for (let i = 0; i < valuesArr.length; i++) {
                    const valueIsInOptions = options.some(
                        (option) =>
                            option[valueKey]?.toString() === valuesArr[i]?.toString()
                    );

                    if (!valueIsInOptions && valuesArr[i] != null) {
                        valuesIsInOptions = false;
                        break;
                    }
                }
            }
        }

        if (!valuesIsInOptions) {
            setLoading(true);
            getValuesFromStore(valuesArr).then((result) => {
                if (!result)
                    loadOptions({
                        [valueKey]: valuesArr,
                    });
                else setLoading(false);
            });
        } else {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEffect(() => {
        if (isLoaded.current) {
            const valuesArr = Array.isArray(value) ? value : [value];

            loadOptions({
                [valueKey]: valuesArr,
            });
        }
    }, [requestUrl]);
    const onSearch = (searchValue: string) => {
        setSearchValue(searchValue);
        if (timer.current) clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            loadOptions({
                [valueKey]: Array.isArray(value) ? value : [value],
                [labelKey ?? "name"]: searchValue,
            });
        }, 500);
    };
    const preparedOptions = options.map((option) => {
        const value = option?.[valueKey ?? "value"];
        return {
            label: option?.[labelKey ?? "name"],
            value: valueToString ? (value ? value.toString() : value) : value,
            option: option,
        };
    });

    return {
        options: preparedOptions,
        searchValue,
        loading,
        onSearch,
        loadOnFocus,
    };
};
