import { bizProcTemplateModel } from "@entities/Portal/BizProcTemplate";
import { entityModel } from "@entities/Portal/Entity";
import { ImportButton } from "@features/Portal/Action/Import";
import { setDocumentTitle } from "@shared/lib/document";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { useHeaderLayout } from "@shared/ui/Header";
import { CategoriesListWithBizProcTemplates } from "@widgets/Portal/CategoryWidgets/CategoriesListWithBizProcTemplates";
import { Space } from "antd";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const BizProcesTemplatesPage = () => {
    const params = useParams();
    const entityId = +(params?.entityId ?? 0);
    const entity = useSelector((state: RootState) =>
        entityModel.selectEntityById(state, entityId)
    );
    bizProcTemplateModel.useLoadBizProcTemplates(entityId, true);
    useEffect(() => {
        if (entity) setDocumentTitle(`${entity.name} (Шаблоны)`);
    }, [entity?.name]);
    entityModel.useLoadEntities(true);
    useHeaderLayout({
        title: entity?.name,
    });

    return (
        <div>
            <IndentContainer>
                <Space>
                    <ImportButton />
                </Space>
            </IndentContainer>

            <CategoriesListWithBizProcTemplates entityId={entityId} />
        </div>
    );
    // return (
    //     <div>
    //         <IndentContainer>
    //             <Space>
    //                 <CreateBizProcTemplateItemButton entity={entity} />
    //                 <ImportButton />
    //             </Space>
    //         </IndentContainer>
    //         <BizProcTemplateItemList
    //             bizProcTemplates={bizProcTemplates}
    //             bottomActions={(bizProcTemplate) => (
    //                 <ExportButton
    //                     url={`bizproc-template/${bizProcTemplate.id}/export`}
    //                     filename={bizProcTemplate.name}
    //                 />
    //             )}
    //         />
    //     </div>
    // );
};

export default BizProcesTemplatesPage;
