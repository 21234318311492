import { openDrawer } from "@shared/ui/DrawerManager";
import { ErrorBoundaryNoInfo } from "@shared/ui/ErrorBoundary";
import BooleanInput from "./BooleanInput";
import DateInput from "./DateInput";
import DateTimeInput from "./DateTimeInput";
import EditorMarkdownInput from "./EditorMarkdownInput/EditorMarkdownInput";
import { EntityInput } from "./EntityInput";
import FilesInput from "./FilesInput";
import FloatInput from "./FloatInput";
import NumberInput from "./NumberInput";
import SelectCategoryInput from "./SelectCategoryInput";
import SelectEntityListInput from "./SelectEntityListInput";
import SelectListInput from "./SelectListInput";
import SelectStageInput from "./SelectStageInput";
import StringInput from "./StringInput";
import TextAreaInput from "./TextAreaInput";
import TimeInput from "./TimeInput";
import TimestampInput from "./TimestampInput";

import QrCodeInput from "./QrCodeInput";
import BarCodeInput from "./BarCodeInput";
import { entityRowConstants } from "@entities/Portal/EntityRow";

type TFormInputItem = { isReduceValue?: boolean } & TFieldInput;

const FormInputItem = ({
    isReduceValue,
    inputId,
    field,
    datatype,
    additionalParameters,
    readValueTextStyle,
    value,
    onlyRead,
    fieldsArray,
    entityRow,
    disabled,
    onChange,
}: TFormInputItem) => {
    const onChangeValue = (value: any, extraInfo?: any) => {
        onChange(value, inputId, extraInfo);
    };

    const getOnClickNameFunction = () => {
        if (field?.is_name) {
            const id =
                entityRow?.id ?? fieldsArray?.find((field) => field.key === "id")?.value;

            if (id && field.entity_table_name) {
                return (e: any) => {
                    e?.stopPropagation();
                    e?.preventDefault();
                    openDrawer({
                        id: id,
                        name: field.entity_table_name!,
                        pattern: entityRowConstants.ERP_LOCATION_PATTERN,
                    });
                };
            }
        }
        return undefined;
    };

    const inputProps = {
        isReduceValue,
        readValueTextStyle,
        value,
        disabled,
        onlyRead,
        additionalParameters,
        onChangeValue,
    };

    const getInput = () => {
        if (datatype === "integer") return <NumberInput {...inputProps} />;
        if (datatype === "string")
            return <StringInput onClickName={getOnClickNameFunction()} {...inputProps} />;
        if (datatype === "externalKey")
            return <StringInput onClickName={getOnClickNameFunction()} {...inputProps} />;
        if (datatype === "boolean") return <BooleanInput {...inputProps} />;
        if (datatype === "longText") return <TextAreaInput {...inputProps} />;
        if (datatype === "json") return <TextAreaInput {...inputProps} />;
        if (datatype === "editor") return <EditorMarkdownInput {...inputProps} />;
        if (datatype === "date") return <DateInput {...inputProps} />;
        if (datatype === "datetime") return <DateTimeInput {...inputProps} />;
        if (datatype === "timestamp") return <TimestampInput {...inputProps} />;
        if (datatype === "time") return <TimeInput {...inputProps} />;
        if (datatype === "qrCode") return <QrCodeInput {...inputProps} />;
        if (datatype === "barCode") return <BarCodeInput {...inputProps} />;
        if (datatype === "float") return <FloatInput {...inputProps} />;
        if (field !== undefined) {
            if (datatype === "list")
                return <SelectListInput field={field} {...inputProps} />;
            if (datatype === "category")
                return (
                    <SelectCategoryInput field={field as TEntityField} {...inputProps} />
                );
            if (datatype === "stage")
                return (
                    <SelectStageInput
                        fieldsArray={fieldsArray as (TEntityField & { value: any })[]}
                        field={field as TEntityField}
                        {...inputProps}
                    />
                );
            if (datatype === "entityList" || datatype === "parentEntity")
                return <SelectEntityListInput field={field} {...inputProps} />;
            if (datatype === "entity" || datatype === "childEntity")
                return <EntityInput field={field} {...inputProps} />;
            if (datatype === "file") return <FilesInput field={field} {...inputProps} />;
        }
    };
    const input = getInput();
    if (input) {
        return <ErrorBoundaryNoInfo>{input}</ErrorBoundaryNoInfo>;
    }

    return <></>;
};

export default FormInputItem;
