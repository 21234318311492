import {
    LOCATION_FILE_VIEWER_FILE_NAME_INDEX,
    LOCATION_FILE_VIEWER_ID_INDEX,
    LOCATION_FILE_VIEWER_PATTERN,
} from "@entities/Portal/EntityField";
import { entityRowConstants } from "@entities/Portal/EntityRow";
import { techMapTemplateConstant } from "@entities/Production/TechMapTemplate";
import { TechMapTmplOperationForm } from "@features/Production/TechMapTemplateFeatures/TechMapTmplOperationForm";

import { DrawerDefault, DrawerManager } from "@shared/ui/DrawerManager";
import { FileViewer } from "@shared/ui/FileViewer";
import { BizProcHistoryListWithLoad } from "@widgets/Portal/BizProcWidgets/BizProcHistory";
import { CreateEditEntityRowDrawer } from "@widgets/Portal/CreateEditEntityRowDrawer";

// interface IDrawerManagerWidget {}
// TODO REFACTOR
const DrawerManagerWidget = () => {
    return (
        <>
            <DrawerManager
                patterns={[
                    {
                        pattern: entityRowConstants.ERP_LOCATION_PATTERN,
                        nameIndex: entityRowConstants.LOCATION_ENTITY_TABLE_INDEX,
                        idIndex: entityRowConstants.LOCATION_ENTITY_ROW_ID_INDEX,
                    },
                    {
                        pattern: techMapTemplateConstant.LOCATION_TMT_OPERATION_PATTERN,
                        nameIndex:
                            techMapTemplateConstant.LOCATION_TMT_OPERATION_NAME_INDEX,
                        idIndex: techMapTemplateConstant.LOCATION_TMT_OPERATION_ID_INDEX,
                    },
                    {
                        pattern: LOCATION_FILE_VIEWER_PATTERN,
                        nameIndex: LOCATION_FILE_VIEWER_FILE_NAME_INDEX,
                        idIndex: LOCATION_FILE_VIEWER_ID_INDEX,
                    },
                ]}
                drawer={(
                    drawerItem: TDrawerManagerItemPrepared,
                    closeDrawer: (item: TDrawerManagerItemPrepared) => void,
                    level?: number
                ) => (
                    <>
                        {drawerItem.pattern ===
                        entityRowConstants.ERP_LOCATION_PATTERN ? (
                            <CreateEditEntityRowDrawer
                                level={level}
                                entityTableName={drawerItem.name}
                                isOpenDrawer={drawerItem.isOpen}
                                entityRowId={drawerItem.id}
                                hideHistory={false}
                                history={(props) => (
                                    <BizProcHistoryListWithLoad {...props} />
                                )}
                                initialValues={drawerItem.additional?.initialValues}
                                openCloseDrawerWithNavigate={() =>
                                    closeDrawer(drawerItem)
                                }
                            />
                        ) : drawerItem.pattern === LOCATION_FILE_VIEWER_PATTERN ? (
                            <DrawerDefault
                                maskClosable={true}
                                open={drawerItem.isOpen}
                                onClose={() => closeDrawer(drawerItem)}
                            >
                                <FileViewer
                                    linkToFile={drawerItem.name}
                                    closeDrawer={() => closeDrawer(drawerItem)}
                                />
                            </DrawerDefault>
                        ) : (
                            <DrawerDefault
                                maskClosable={false}
                                open={drawerItem.isOpen}
                                onClose={() => closeDrawer(drawerItem)}
                            >
                                <TechMapTmplOperationForm
                                    operationUuid={drawerItem.additional?.uuid}
                                    parentUuid={drawerItem.additional?.parentUuid ?? null}
                                    tmtUuid={drawerItem.additional?.tmtUuid ?? null}
                                    operationId={drawerItem.id}
                                    tmtId={drawerItem.additional?.tmtId}
                                    level={level}
                                    onCancel={() => closeDrawer(drawerItem)}
                                />
                            </DrawerDefault>
                        )}
                    </>
                )}
            />
        </>
    );
};

export default DrawerManagerWidget;
