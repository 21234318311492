import { Pagination, Table } from "antd";
import { useTMTable } from "../model";
import { getGroupedTableColumns } from "./TableColumns/GroupedTableColumns";
import { getTableColumns } from "./TableColumns/TableColumns";
import { techMapLib } from "@entities/Production/TechMap";

interface ITMTable {
    level: number;
    parentKey?: string | number;
    additionalFilters?: TTechMapFilters;
}

const TMTable = ({ level, parentKey, additionalFilters }: ITMTable) => {
    const {
        levelKey,
        isGrouped,
        groupLevel,
        currentPage,
        canReviewSome,
        lastPage,
        loading,
        perPage,
        techMaps,
        onShowSizeChange,
    } = useTMTable(level, parentKey, additionalFilters);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "100%",
                overflow: "auto",
                height: "100%",
                flex: "1 1 200px",
                background: "white",
            }}
        >
            {isGrouped ? (
                <Table
                    key="1"
                    loading={loading}
                    pagination={false}
                    rowKey={"value"}
                    dataSource={techMaps as TTechMapGrouped[]}
                    columns={getGroupedTableColumns(groupLevel!)}
                    expandable={
                        isGrouped
                            ? {
                                  expandRowByClick: true,
                                  expandedRowRender: (
                                      record,
                                      index,
                                      indent,
                                      expanded
                                  ) => {
                                      if (!expanded) return <></>;

                                      return (
                                          <TMTable
                                              parentKey={`${levelKey}${record.value}`}
                                              additionalFilters={{
                                                  ...(additionalFilters ?? {}),
                                                  [techMapLib.getGroupLevelFilterKey(
                                                      groupLevel
                                                  )]: [record.value],
                                              }}
                                              // levelId={record[groupingKey]}
                                              level={(level ?? 0) + 1}
                                          />
                                      );
                                  },
                              }
                            : undefined
                    }
                />
            ) : (
                <Table
                    key="1"
                    loading={loading}
                    pagination={false}
                    rowKey={"id"}
                    dataSource={techMaps as TTechMapStarted[]}
                    columns={getTableColumns(canReviewSome, levelKey)}
                />
            )}

            <div
                style={{
                    position: "sticky",
                    bottom: 0,
                    right: 0,
                    left: 0,
                    padding: "4px 15px",
                    background: "white",
                    zIndex: 999,
                    marginTop: 20,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: 0 ? "space-between" : "flex-end",
                }}
            >
                <Pagination
                    hideOnSinglePage={isGrouped}
                    showSizeChanger
                    onChange={onShowSizeChange}
                    total={lastPage * perPage}
                    pageSize={perPage}
                    current={currentPage}
                />
            </div>
        </div>
    );
};

export default TMTable;
